@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divider {
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
    margin-top: 15px;
}

.bg__chat__image {
    background-image: url("/public/back2.jpg");
    background-repeat: repeat;
    background-size: contain;
}

.border__chat__color {
    border-color: rgb(112,184,250);
}

.bot__cloud {
    border-radius: 20px 20px 20px 0;
}

.user__cloud {
    border-radius: 20px 20px 0 20px;
}

.questions__list__block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.custom__icon {
    color: #346bb4;
}

.box__line {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 200px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 2px solid rgb(34,197,94);
    border-right: 2px solid rgb(34,197,94);
    border-bottom: 2px solid rgb(34,197,94);
}

.box__line__close {
    position: absolute;
    right: -12px;
    top: 10px;
    height: 22px;
    width: 22px;
    color: #ffffff;
    cursor: pointer;
    background-color: #bd6f6f;
    border-radius: 50%;
    transition: .5s;
}

.box__line__close:hover {
    background-color: #9b2323;
}

.label__circle {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f1f1f1;
}

.box__auto__line {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 200px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 2px solid rgb(26, 61, 176);
    border-right: 2px solid rgb(26, 61, 176);
    border-bottom: 2px solid rgb(26, 61, 176);
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.margin__auto {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.number__card {
    font-size: 10px;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: #f5975a4d;
    margin-left: 10px;
    color: #994610;
    font-weight: 600;
}

.group__column {
    padding: 20px 20px 0 20px;
    background-color: #fffbf9;
    border: 1px solid #f8e8e0;
    border-radius: 10px;
    margin-bottom: 50px;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.points__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
}
.loader__points__text {
    color: #f0f2f7;
    padding-left: 30px;
    font-size: 14px;
}

.snippet {
    display: inline-block;
    /*box-shadow: 0 .4rem .8rem -.1rem rgba(231, 231, 231, 0.1), 0 0 0 1px #c4c4c4;*/
    box-shadow: 0px 3px 4px 1px rgb(171 171 171 / 20%);
    border-radius: 25rem;
    background-color: #2c2c2c;
    padding: 0 15px;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    overflow: hidden;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    box-shadow: 9999px 0 0 -5px #ffffff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #ffffff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #ffffff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #ffffff;
    }
    30% {
        box-shadow: 9984px 0 0 2px #ffffff;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #ffffff;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #ffffff;
    }
    30% {
        box-shadow: 9999px 0 0 2px #ffffff;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #ffffff;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #ffffff;
    }
    30% {
        box-shadow: 10014px 0 0 2px #ffffff;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #ffffff;
    }
}
